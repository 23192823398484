<template>
  <div class="perk-details py-8">
    <PerkAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" class="perk-details__title" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="perk"
        name="perks"
        redirect="/perks"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="perk-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="perk-details__divider"></v-divider>

            <PerkForm class="perk-details__form" :perk="perk" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Perk
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import PerkForm from '@/views/Home/Perks/components/PerkForm'
import PerkAppbar from '@/views/Home/Perks/components/PerkAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'PerkDetails',

  components: {
    ViewingTitle,
    PerkAppbar,
    PerkForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      perk: (state) => state.perk.perkDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.perk ? this.perk.header : null
    },

    hasDeletePermission() {
      return validatePermissions([PERMISSION.PERKS_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getPerkDetails: 'perk/getPerkDetails',
    }),

    ...mapMutations({
      clearPerkDetails: 'perk/clearPerkDetails',
    }),

    async getPerk() {
      this.loading = true
      await this.getPerkDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getPerk()
  },

  destroyed() {
    this.clearPerkDetails()
  },

  watch: {
    $route() {
      this.getPerk()
    },
  },
}
</script>

<style lang="scss" scoped>
.perk-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
